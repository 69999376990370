import clsx from 'clsx'
import Image, {ImageProps} from 'next/image'
import {HTMLProps, useEffect, useState} from 'react'
import {useInView} from 'react-intersection-observer/useInView'

export interface LoadableImageProps
  extends ImageProps,
    Pick<HTMLProps<HTMLImageElement>, 'style'> {
  /**
   * Border radius of the image component.
   *
   * @default 'rounded-2xl md:rounded-3xl'
   */
  borderRadius?: string
  /**
   * Border radius of placeholder component.
   *
   * @default 'rounded-2xl md:rounded-3xl'
   */
  placeholderBorderRadius?: string
  /**
   * Determines background color of pulsating placeholder element. Should be set
   * based on the placeholder's environment.
   *
   * @default 'dark'
   */
  placeholderColor?: 'light' | 'dark' | 'transparent'
  /**
   * Scale of the placeholder element. Should be a Tailwind class for scale.
   *
   * @default 'scale-100'
   */
  placeholderScale?: string
  /**
   * Class names applied to the wrapper component.
   */
  wrapperClassName?: string
  /**
   * Determines whether or not the kenburns effect should be applied to the
   * image when it appears in the view. This blocks all other animations.
   *
   * @default false
   */
  kenburns?: boolean
  /**
   * Determines whether or not the default animation should be disabled for this
   * image.
   *
   * @default false
   */
  disableDefaultAnimation?: boolean
}

export function LoadableImage({
  src,
  alt,
  onLoadingComplete,
  className,
  placeholder,
  borderRadius = 'rounded-2xl md:rounded-3xl',
  placeholderBorderRadius = 'rounded-2xl md:rounded-3xl',
  placeholderColor = 'dark',
  placeholderScale = 'scale-100',
  wrapperClassName,
  kenburns,
  disableDefaultAnimation,
  style,
  ...props
}: LoadableImageProps) {
  const [loaded, setLoaded] = useState(false)
  const [mounted, setMounted] = useState(false)
  const {ref, inView} = useInView({
    triggerOnce: true,
    threshold: 0.25,
    skip: disableDefaultAnimation,
  })

  let placeholderColorClassName = 'bg-gray-200'

  if (placeholderColor === 'light') {
    placeholderColorClassName = 'bg-white bg-opacity-40'
  } else if (placeholderColor === 'transparent') {
    placeholderColorClassName = 'bg-transparent'
  }

  useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  function handleLoadingComplete(result: {
    naturalWidth: number
    naturalHeight: number
  }) {
    if (onLoadingComplete) {
      onLoadingComplete(result)
    }

    if (mounted) {
      setLoaded(true)
    }
  }

  return (
    <div
      ref={ref}
      className={clsx(
        'relative grid overflow-hidden loadable-image-wrapper',
        !disableDefaultAnimation &&
          (inView ? 'motion-safe:animate-fade-in-slide-up' : 'opacity-0'),
        wrapperClassName,
        borderRadius,
      )}
      style={style}
    >
      <div
        className={clsx(
          'absolute top-0 left-0 right-0 bottom-0 w-full h-full transition-opacity motion-safe:animate-pulse overflow-hidden',
          placeholderScale,
          borderRadius ?? placeholderBorderRadius,
          placeholderColorClassName,
          loaded ? 'opacity-0 invisible' : 'opacity-100 visible',
        )}
      />

      <Image
        className={clsx(
          'transition-opacity z-10 overflow-hidden',
          borderRadius,
          loaded ? 'opacity-100' : 'opacity-0',
          loaded && kenburns && 'motion-safe:hover:animate-kenburns',
          className,
        )}
        src={src}
        alt={alt}
        onLoadingComplete={handleLoadingComplete}
        {...props}
      />
    </div>
  )
}

export default LoadableImage
