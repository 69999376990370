import clsx from 'clsx'
import {HTMLProps} from 'react'

export function Chip({
  className,
  children,
  ...props
}: HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={clsx(
        'flex bg-gray-800 bg-opacity-10 rounded-lg px-3 py-1 min-h-0',
        className,
      )}
      {...props}
    >
      <span className="text-typography font-semibold text-sm p-0">
        {children}
      </span>
    </div>
  )
}

export default Chip
