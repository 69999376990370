import clsx from 'clsx'
import {HTMLProps} from 'react'

export interface GridItemProps extends HTMLProps<HTMLDivElement> {
  /**
   * Determines whether default padding should be disabled or not.
   *
   * @default false
   */
  disableDefaultPadding?: boolean
}

export function GridItem({
  className,
  children,
  disableDefaultPadding,
  ...props
}: GridItemProps) {
  return (
    <div
      className={clsx(!disableDefaultPadding && 'px-0 md:px-4', className)}
      {...props}
    >
      {children}
    </div>
  )
}

export default GridItem
