import clsx from 'clsx'
import {LinkProps} from 'next/link'
import {HTMLProps} from 'react'
import {useInView} from 'react-intersection-observer/useInView'
import Chip from '../../atoms/chip'
import Heading from '../../atoms/heading'
import LinkIcon from '../../atoms/icons/link-icon'
import Link from '../../atoms/link'
import LoadableImage, {LoadableImageProps} from '../../atoms/loadable-image'
import Paragraph from '../../atoms/paragraph'

export interface CaseStudyCardProps
  extends LoadableImageProps,
    Pick<HTMLProps<HTMLDivElement>, 'className'>,
    Pick<LinkProps, 'href'> {
  /**
   * Name of the company for whom this case study was made.
   */
  title: string
  /**
   * List of case study categories.
   *
   * @default null
   */
  categories?: Array<string>
  /**
   * Class name passed to the image component.
   *
   * @default null
   */
  imageClassName?: string
  /**
   * Determines whether link icon should be displayed next to Case Study's
   * title.
   *
   * @default true
   */
  showLinkIcon?: boolean
}

const animations = [
  'motion-safe:animate-fade-in-slide-up',
  'motion-safe:animate-fade-in-slide-up',
  'motion-safe:animate-fade-in-slide-up',
  'motion-safe:animate-fade-in-slide-up-delay-sm',
  'motion-safe:animate-fade-in-slide-up-delay-md',
  'motion-safe:animate-fade-in-slide-up-delay-lg',
]

export function CaseStudyCard({
  src,
  alt,
  title,
  categories,
  className,
  children,
  href,
  showLinkIcon = true,
  ...props
}: CaseStudyCardProps) {
  const {ref, inView} = useInView({triggerOnce: true, threshold: 0.1})

  return (
    <Link className="group" fontWeight="normal" disableActiveState href={href}>
      <div
        ref={ref}
        className={clsx(
          'grid gap-4',
          inView
            ? 'motion-safe:animate-fade-in-slide-up motion-reduce:opacity-100'
            : 'opacity-0',
          className,
        )}
        {...props}
      >
        <LoadableImage
          src={src}
          alt={alt}
          width={558}
          height={300}
          quality={95}
          borderRadius="rounded-2xl"
          layout="responsive"
          sizes="(max-width: 640px) 576px, 640px"
          disableDefaultAnimation
        />

        <div className="grid">
          <Heading
            variant="h3"
            className="transition-colors group-hover:text-primary group-active:text-primary flex items-center"
          >
            {title}

            {showLinkIcon && (
              <LinkIcon className="inline-block ml-1.5 md:ml-2 w-4 h-4 md:w-5 md:h-5" />
            )}
          </Heading>

          <Paragraph className="transition-colors group-hover:text-primary group-active:text-primary">
            {children}
          </Paragraph>
        </div>

        {categories && (
          <div className="grid grid-flow-col place-content-start gap-2">
            {categories.map(category => (
              <Chip key={category}>{category}</Chip>
            ))}
          </div>
        )}
      </div>
    </Link>
  )
}

export default CaseStudyCard
