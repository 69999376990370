import clsx from 'clsx'
import dynamic from 'next/dynamic'
import {HTMLProps} from 'react'
import ActivityIndicator from '../../atoms/activity-indicator'
import Heading from '../../atoms/heading'
import Paragraph from '../../atoms/paragraph'

export interface CallToActionProps extends HTMLProps<HTMLDivElement> {
  /**
   * Title of the call to action box.
   *
   * @default null
   */
  title?: string
  /**
   * Label of the call to action button.
   *
   * @default null
   */
  callToActionText?: string
}

const Button = dynamic(() => import('../../atoms/button'), {
  ssr: false,
  loading: () => <ActivityIndicator className="text-primary" />,
})

export function CallToAction({
  href,
  className,
  children,
  title,
  callToActionText,
  ...props
}: CallToActionProps) {
  return (
    <div
      className={clsx(
        'grid grid-flow-row rounded-3xl bg-cta border-primary-extra-light border-2 p-8 gap-4 w-full shadow-card',
        'md:grid-flow-col md:gap-8 md:w-3/4 md:mx-auto',
        className,
      )}
      {...props}
    >
      <div className="grid gap-2">
        <Heading component="h2" variant="h3" disableMaxWidth>
          {title}
        </Heading>

        <Paragraph>{children}</Paragraph>
      </div>

      <div className="grid items-center">
        {href && callToActionText && (
          <Button
            variant="primary"
            renderAsLink
            href={href}
            className="justify-self-start md:justify-self-end"
          >
            {callToActionText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default CallToAction
