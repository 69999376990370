import clsx from 'clsx'
import {HTMLProps} from 'react'
import {CaseStudy, DataWithImage} from '../../../types'
import Container from '../../atoms/container'
import GridContainer from '../../atoms/grid-container'
import GridItem from '../../atoms/grid-item'
import Heading from '../../atoms/heading'
import CaseStudyCard, {
  CaseStudyCardProps,
} from '../../molecules/case-study-card'

export interface CaseStudySectionProps
  extends HTMLProps<HTMLDivElement>,
    Pick<CaseStudyCardProps, 'showLinkIcon'> {
  /**
   * List of case studies to be displayed here.
   */
  caseStudies: Array<CaseStudy & Partial<DataWithImage>>
  /**
   * Title of this section. Pass a translated value.
   *
   * @default null
   */
  title?: string
  /**
   * Determines whether section should have a highlighted background or not.
   *
   * @default false
   */
  highlighted?: boolean
  /**
   * A list of column numbers to be applied to the component.
   *
   * @default ['grid-cols-1', 'md:grid-cols-2']
   */
  columns?: Array<string>
}

export function CaseStudySection({
  title,
  caseStudies,
  highlighted,
  className,
  children,
  showLinkIcon,
  columns = ['grid-cols-1', 'md:grid-cols-2'],
}: CaseStudySectionProps) {
  const sectionContent = (
    <Container
      component={highlighted ? 'div' : 'section'}
      className={clsx(
        'grid gap-16 justify-items-center',
        highlighted ? className : undefined,
      )}
    >
      {title && (
        <Heading variant="h2" className="text-center" disableMaxWidth>
          {title}
        </Heading>
      )}

      <GridContainer
        disableDefaultColumns
        className={clsx('gap-8 md:gap-0 md:gap-y-8', ...columns)}
      >
        {caseStudies.map(
          ({
            title,
            listThumbnailPhoto,
            listThumbnailPhotoAlt,
            categories,
            slug,
            shortDescription,
          }) => (
            <GridItem key={title}>
              <CaseStudyCard
                src={listThumbnailPhoto}
                alt={listThumbnailPhotoAlt}
                title={title}
                categories={categories}
                href={`/case-studies/${slug}`}
                showLinkIcon={showLinkIcon}
              >
                {shortDescription}
              </CaseStudyCard>
            </GridItem>
          ),
        )}
      </GridContainer>

      {children}
    </Container>
  )

  if (highlighted) {
    return (
      <section className={clsx('bg-highlighted py-12', className)}>
        {sectionContent}
      </section>
    )
  }

  return sectionContent
}

export default CaseStudySection
